import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { useIntl, Link } from "gatsby-plugin-react-intl"

const LatestNews = () => {
  const intl = useIntl()

  const { latestNews } = useStaticQuery(graphql`
    query WordPressLatestNews {
      latestNews: allWpPost(sort: { fields: [date], order: DESC }, limit: 2) {
        nodes {
          id
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    width: 120
                    height: 90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!latestNews?.nodes || latestNews.nodes === 0) return null

  return (
    <div className="w-full recentposts lg:col-span-2">
      <h3 className="text-base font-bold text-white uppercase font-Lato mb-11">
        {intl.formatMessage({ id: "Latest News" })}
      </h3>
      {latestNews.nodes.map((post, i) => {
        return (
          <div key={post.id + "-post"} className="pb-5">
            <ul className="flex flex-row gap-x-2 gap-y-5">
              <li>
                <Link
                  to={`/blog${post?.uri}`}
                  aria-label={post?.featuredImage?.node?.altText || ""}
                >
                  <GatsbyImage
                    key={post.id + "-image"}
                    image={
                      post?.featuredImage?.node?.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                    alt={post?.featuredImage?.node?.altText || ""}
                    className="transition duration-300 ease-in-out delay-150 hover:filter hover:brightness-50"
                  />
                </Link>
              </li>
              <li className="items-center justify-center mb-2 ml-1">
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <div key={post.id + "-body-header"}>
                    <h4 className="-mt-2">
                      <Link to={`/blog${post.uri}`}>
                        <span className="text-xs tracking-wider text-white uppercase transition duration-300 ease-in-out delay-150 font-PTSerif hover:text-themeAccent">
                          {parse(post.title)}
                        </span>
                      </Link>
                    </h4>
                    <p className="pb-1 text-xs font-Lato text-themeAccent">
                      <span key={post.id + "-body-span"}>{post.date}</span>
                    </p>
                  </div>
                  <div
                    key={post.id + "-body-section"}
                    className="text-sm text-white font-Lato line-clamp-2"
                  >
                    {parse(post.excerpt, {
                      replace: ({ attribs }) =>
                        attribs && attribs.class === "read-more" && <></>,
                    })}
                  </div>
                </article>
              </li>
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default LatestNews
