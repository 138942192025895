import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"

export default function LanguageSelector({ label, className }) {
  const intl = useIntl()
  const labelText = label || "Languages"
  const selectorClass = className || "language-selector"

  return (
    <li
      className={`${selectorClass} self-center lang-sel`}
      data-label={labelText}
    >
      {intl.locale === "es" && (
        <Link to="/" className="flex gap-x-1 justify-between items-center">
          <StaticImage
            src="../assets/USA-flag-circle.png"
            alt="USA Flag"
            placeholder="blurred"
            layout="fixed"
            width={24}
            height={24}
            title="English"
          />
        </Link>
      )}
      {intl.locale === "en" && (
        <Link to="/es" className="flex gap-x-1 justify-between items-center">
          <StaticImage
            src="../assets/Spain-flag-circle.png"
            alt="Spain Flag"
            placeholder="blurred"
            layout="fixed"
            width={24}
            height={24}
            title="Español"
          />
        </Link>
      )}
    </li>
  )
}
