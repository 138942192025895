import React from "react"
import { useIntl, Link } from "gatsby-plugin-react-intl"

const RequestButton = () => {
  const intl = useIntl()
  return (
    <div>
      <Link
        to="/contact/"
        className="request-button font-Lato font-black tracking-wider bg-themeAccent text-white text-sm px-10 py-3 my-3 hover:bg-themeAccentDarker transition delay-150 duration-300 ease-in-out"
      >
        {intl.formatMessage({
          id: "Request a Free Consultation",
        })}
      </Link>
    </div>
  )
}

export default RequestButton
