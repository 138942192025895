import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

const GoogleMap = () => {
  const intl = useIntl()
  return (
    <div className="min-w-full bg-[#385c74] mt-16 min-h-[450px]">
      <iframe
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.637239831019!2d-73.63522828522747!3d40.70398634604379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c27cdf761eb4ab%3A0xfc68f2f7462be7f1!2s95%20Front%20St%2C%20Hempstead%2C%20NY%2011550%2C%20USA!5e0!3m2!1s${intl.locale}!2sin!4v1627453061168!5m2!1s${intl.locale}!2sin`}
        className="min-w-full min-h-[450px] font-Lato"
        title="Google Map"
      />
    </div>
  )
}

export default GoogleMap
